import React from "react"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import InsiderHero from "../components/insider/InsiderHero"
import CurrentInsiders from "../components/insider/CurrentInsiders"
import PastInsiders from "../components/insider/PastInsiders"

const Insider = () => {
  return (
    <>
      <Seo title="7107 Insider" />
      <BlurredSection>
        <InsiderHero />
        <CurrentInsiders />
        {/* <PastInsiders /> */}
      </BlurredSection>
    </>
  )
}

export default Insider
