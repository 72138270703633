import React from "react"
import Title from "../Title"
import Section from "../Section"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import LineVector from "../LineVector"
import ArrowCta from "../ArrowCta"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CurrentInsiders = () => {
  const currentYear = useStaticQuery(graphql`
    query AllCurrentYear {
      allInsiderJson(filter: { year: { eq: 2021 } }) {
        edges {
          node {
            id
            year
            issue_no
            title
            link
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Container>
        {currentYear &&
          currentYear.allInsiderJson.edges.map((i, idx) => (
            <RowStyled key={i.node.id} align="center">
              <Col md={6} lg={4} orderLg={idx % 2 === 1 && "last"}>
                <GatsbyImage image={getImage(i.node.thumbnail)} alt="insider" />
              </Col>
              <Col lg={8}>
                <Issue>Issue no. {i.node.issue_no}</Issue>
                <Title size={3} lg={2}>
                  {i.node.title}
                </Title>
                <LineVector height={6} width={154} />
                <Description
                  dangerouslySetInnerHTML={{ __html: i.node.description }}
                />
                <ArrowCta target={i.node.link} title="Read Now" />
              </Col>
            </RowStyled>
          ))}
      </Container>
    </Section>
  )
}

const Issue = styled.div`
  font-weight: 300;
  text-transform: uppercase;
`

const RowStyled = styled(Row)`
  & + & {
    margin-top: 40px;
  }
`

const Description = styled.div`
  font-size: 14px;
  line-height: 1.8;
  margin-top: 1rem;

  p:last-child {
    margin-bottom: 0;
  }
`

export default CurrentInsiders
