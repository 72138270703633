import React, { useState } from "react"
import Title from "../Title"
import Section from "../Section"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import styled from "styled-components"
import { Accordion } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import MinusIcon from "../Icons/MinusIcon"
import PlusIcon from "../Icons/PlusIcon"

import { theme } from "../../utils/styled_config"

const PastInsiders = () => {
  const [activeId, setActiveId] = useState(0)
  const pastYears = useStaticQuery(graphql`
    query AllPastYearsQuery {
      allInsiderJson(sort: {fields: [year], order: ASC}) {
        edges {
          node {
            id
            year
            issue_no
            title
            link
          }
        }
      }
    }
  `)
  let allYears = [];
  pastYears.allInsiderJson.edges.map(y => allYears.push(y.node.year));
  allYears = Array.from(new Set(allYears)).sort((a, b) => (a > b ? -1 : 1));

  const accordionClick = id => {
    if (activeId === id) {
      setActiveId(null)
    } else {
      setActiveId(id)
    }
  }

  return (
    <Section>
      <Container>
        <TitleStyled size={2} lg="d3">
          Previous Issues
        </TitleStyled>
        <Row justify="center">
          <Col lg={10}>
            <Accordion defaultActiveKey="0">
              {pastYears &&
                allYears.map((y, idx) => (
                  <Accordion.Item eventKey={idx.toString()} key={idx}>
                    <Accordion.Button onClick={() => accordionClick(idx)}>
                      <Title size={3} lg={2}>
                        {y}
                      </Title>
                      {activeId === idx ? <MinusIcon /> : <PlusIcon />}
                    </Accordion.Button>
                    <AccordionBody>
                      {pastYears.allInsiderJson.edges
                        .filter(i => i.node.year === y)
                        .map(pi => (
                          <PreviousIssue
                            key={pi.node.id}
                            href={pi.node.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <RowStyled align="center">
                              <Col col="auto">
                                <Issue>Issue no. {pi.node.issue_no}</Issue>
                              </Col>
                              <Col col="auto">
                                <Title size={3}>{pi.node.title}</Title>
                              </Col>
                            </RowStyled>
                          </PreviousIssue>
                        ))
                      }
                    </AccordionBody>
                  </Accordion.Item>
                ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const PreviousIssue = styled.a`
  display: block;
  color: #fff;
  transition: ${theme.transition};

  &:hover {
    color: ${theme.color.brand};
  }

  & + & {
    margin-top: 16px;
  }
`

const AccordionBody = styled(Accordion.Body)`
  display: flex;
  flex-direction: column-reverse;
  a {
    &:first-of-type {
      margin-top: 16px;
    }&:last-of-type {
      margin-top: 0;
    }
  }
`

const TitleStyled = styled(Title)`
  text-align: center;
`

const Issue = styled.div`
  font-weight: 300;
  text-transform: uppercase;
`

const RowStyled = styled(Row)`
  flex-wrap: nowrap;
`

export default PastInsiders
